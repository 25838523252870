import React from 'react'
import './App.css'
import NavBar from './components/Navbar/NavBar'

import Convenience from './components/Convenience/Convenience'

import FAQsection from './components/FAQsection/FAQsection'
import Testimonials from './components/Testimonials/Testimonials'
import RegistrationForm from './components/Register/RegistrationForm'
import Footer from './components/Footer/Footer'
import Blog from './components/Blog/Blog'
import LandingPage from './components/Landingpage/LandingPage'
import Goat from './components/goat/Goat'

function App() {
  return (
    <div className='App'>
      
      <NavBar/>
      <LandingPage/>
      <Goat/>
      <Blog/>
      
      

      <Convenience/>
      <FAQsection/>
      <Testimonials/>
      <RegistrationForm/>
      <Footer/>

    </div>
  )
}

export default App
