import React from 'react';
import './Testimonials.css'

function Testimonials ()  {
  const testimonialsData = [
    {
      id: 1,
      name: 'John Doe',
      quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.',
    },
    {
      id: 2,
      name: 'Jane Smith',
      quote: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
    },
    {
      id: 3,
      name: 'Bob Johnson',
      quote: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.',
    },
  ];

  return (
    <div className="Testimonials">
      <h2>Testimonials</h2>
      {testimonialsData.map((testimonial) => (
        <div key={testimonial.id}>
          <p>{testimonial.quote}</p>
          <p>{testimonial.name}</p>
        </div>
      ))}
    </div>
  );
};

export default Testimonials;
