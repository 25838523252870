import React from 'react';
import './Blog.css'
import Image2 from './image2.jpg'

const Blog = () => {
  return (
    <section id="#blog">
    <div className="blogsection" id='#blogsection'>
    <div className="blog">
    <img src={Image2} alt='Image2'/>
    <h2>Our Illuminati - Understanding Our Secret Society</h2>
      <p className='p-blog'>Our Illuminati is a secret society that has been the subject of many conspiracy theories and myths. It was founded in Bavaria, Germany, in 1776 by a group of intellectuals, free thinkers, and progressive politicians, and I am proud to say that I am one of the leaders of this esteemed organization. Our society was established to promote enlightenment ideas and oppose the oppressive rule of the church and monarchs.</p>
        <h2>Our Goals as Illuminati</h2>
        <p className='p-blog'>Our primary goal as Illuminati leaders is to create a new world order where everyone has equal rights and opportunities, and all forms of oppression are eliminated. We believe that a small group of people should control the world's resources and power to create a more efficient and equitable society. Our ultimate goal is to bring about a global government, economy, and religion that is run by a small group of enlightened individuals.</p>
        <h2>Our Offer as Illuminati Leaders</h2>
        <p className='p-blog'>As leaders of the Illuminati, we offer our members a community of like-minded individuals who share our beliefs and values. We provide members with opportunities to network, collaborate, and advance their careers. Members also have access to exclusive events, resources, and knowledge that can help them achieve their goals.</p>
        <p className='p-blog'>Our Illuminati membership is selective and based on merit. We only accept individuals who have demonstrated exceptional abilities, intelligence, and dedication to their goals. Members are sworn to secrecy and must adhere to a strict code of conduct that promotes ethical behavior, responsibility, and accountability.</p>
        <h2>Debunking the Myths about Our Illuminati</h2>
        <p className='p-blog'>Despite our noble goals as Illuminati leaders, we have been the subject of many conspiracy theories and myths. Some people believe that we are a secret organization that controls the world's governments and economy. Others claim that we worship Satan and engage in dark rituals.</p>
        <p className='p-blog'>However, these claims are baseless and unfounded. Our Illuminati is not a secret society that controls the world's governments or economy. We do not engage in satanic rituals or worship any deity. As leaders of the Illuminati, we are a group of intellectuals who are committed to promoting enlightenment ideas and advancing human progress.</p>
        </div>

    </div>
    </section>
  );
};

export default Blog;
