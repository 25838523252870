import React from 'react';
import { FaMoneyBillAlt, FaGlobe,  FaMapMarkerAlt, FaHeadset, FaMobileAlt, FaHandshake } from 'react-icons/fa';
import './Convinience.css'


const Convenience = () => {
  return (
    <section id='convenience'>
    <div className="convenience-container">
      <div className="convenience-column">
        <div className="convenience-icon"><FaMoneyBillAlt /></div>
        <h3>Great Value</h3>
        <p>Community brings people together to create a sense of belonging and positive change.</p>
      </div>
      <div className="convenience-column">
        <div className="convenience-icon"><FaGlobe /></div>
        <h3>Worldwide </h3>
        <p>Community unites people worldwide for a better, more compassionate society.</p>
      </div>
      <div className="convenience-column">
        <div className="convenience-icon"><FaHandshake  /></div>
        <h3>Join Hands</h3>
        <p>Community-driven initiatives and support systems can play a crucial role in combating poverty and promoting economic stability.</p>
      </div>
      <div className="convenience-column">
        <div className="convenience-icon"><FaMapMarkerAlt /></div>
        <h3>Visit Us</h3>
        <p>Visit California's inclusive community to connect, experience diversity, and build bridges with East Africans.</p>
      </div>
      <div className="convenience-column">
        <div className="convenience-icon"><FaHeadset /></div>
        <h3>24/7 Help Center</h3>
        <p>We provide round-the-clock assistance and support to ensure that members can get help whenever they need it.</p>
      </div>
      <div className="convenience-column">
        <div className="convenience-icon"><FaMobileAlt /></div>
        <h3>Whatsapp & Instagram
        </h3>
        <p>Contact Us On whatsapp or Instagram On The Go. Contact Us With Proof Of Payment</p>
      </div>
    </div>
    </section>
  );
};

export default Convenience;
