import React from 'react'
import GoatImg from './image1.jpg' 
import './Goat.css'
function Goat() {
  return (
    <div className='Goat'>
        <img className='Goatimage' src={GoatImg} alt='GoatImg'/>
      
    </div>
  )
}

export default Goat
