import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="footer-links">
        <div>About Us | Contact Us | Community Guidelines</div>
        <div>Terms of Use | Privacy Policy | FAQs</div>
        <div>Resources | Social Media | &copy; 2023 The Illuminati</div>
        <div>Support</div>
      </div>
    </footer>
  );
}

export default Footer;
