import React from 'react';

import { Button, Form, Input, Typography } from 'antd';
import './LandingPage.css';

const { Title, Paragraph } = Typography;

function LandingPage() {
  return (
    <div className="landing-page">
      <div className="background-image"></div>
      <Title level={1}>Welcome to our Illuminati Community Recruitment Web</Title>
      <Paragraph>
        Join our community of like-minded individuals who share your interests and passions.
      </Paragraph>
      <p>
        Point To Note: All New Members Must Pay 66USD Before Proceeding to the next Step. Hail The Light.



      </p>
      <Form className="L-form" layout="vertical">
        <Form.Item label="Email address">
          <Input type="email" placeholder="example@example.com" />
        </Form.Item>
        <Button type="primary" htmlType="submit" size="large" href='#regform'>
          Join now
        </Button>
      </Form>
      <Paragraph>
        By clicking "Join now", you agree to our <a href='#regform'>Terms & conditions and privacy policy</a>.
      </Paragraph>
      <Paragraph className="trust-indicator">
        Trusted by over 1,000,000 members worldwide.
      </Paragraph>
    </div>
  );
}

export default LandingPage;
