import React, { useState, useEffect } from 'react';
import './NavBar.css'


function NavBar() {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <nav >
      <div className='Home'>
      <p >
        The Illuminati
      </p>
      </div>
      
      <p className='time'>{dateTime.toLocaleString()}</p>
      
    </nav>
  );
}

export default NavBar;
