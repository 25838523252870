import React, { useState } from "react";
import './FAQsection.css'

function DropdownButton() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="FAQ"> 
        <div className="dropdown">
          <button className="dropdown-button" onClick={toggleDropdown}>
            Frequently Asked Questions
          </button>
          {isOpen && (
            <div className="dropdown-menu">
              
              <div className="question">
                <h3>What is the Illuminati?</h3>
                <p>The Illuminati is a secret society founded in Germany in 1776 to promote enlightenment ideas and oppose oppressive rule.</p>
              </div>
              <div className="question">
                <h3>How Much Do I earn After Joining?</h3>
                <p>New Members Are given a raansom of 10000$ After Initiation</p>
              </div>
              <div className="question">
                <h3>Do I have to sacrifice someone?</h3>
                <p>No: There's No Human sacrifices.</p>
              </div>
              <div className="question">
                <h3>How can I join The Secret Society?</h3>
                <p>Our Agents Will take whoever is willing to join through simple steps all you have to do is get ready to do or  pay exactly what you are asked to</p>
              </div>
            </div>
          )}
        </div>
    </div>
  );
}

export default DropdownButton;
