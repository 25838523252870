import React, { useState } from "react";
import './RegistrationForm.css'

function RegistrationForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [occupation, setOccupation] = useState("");
  const [reasonForJoining, setReasonForJoining] = useState("");
  const [password, setPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [country, setCountry] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      firstName,
      lastName,
      email,
      phoneNumber,
      gender,
      dateOfBirth,
      occupation,
      reasonForJoining,
      password,
      profilePicture,
      country,
      preferredLanguage
    });
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setGender("");
    setDateOfBirth("");
    setOccupation("");
    setReasonForJoining("");
    setPassword("");
    setProfilePicture("");
    setCountry("");
    setPreferredLanguage("");
  };

  return (
    <section id="reform">
    <form onSubmit={handleSubmit} className="regform" id="regform">
        <h1>Register</h1>
        <p className="Note">Note: Once You Provide All your details, Send Message on WhatsApp For Payment Of $66. </p>
        <br />
      <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      <br />
      <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      <br />
      <input type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
      <br />
      <input type="tel" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      <br />
      <label for="gender">Gender</label>
      <select placeholder="Gender" value={gender} onChange={(e) => setGender(e.target.value)}>
        <option value=""></option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
      <br />
      <label for="date">Date Of Birth</label>
      <input type="date" placeholder="Date of Birth" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
      <br />
      <input type="text" placeholder="Occupation" value={occupation} onChange={(e) => setOccupation(e.target.value)} />
      <br />
      <textarea placeholder="Reason for Joining" value={reasonForJoining} onChange={(e) => setReasonForJoining(e.target.value)} />
      <br />
      <label for="file">Passport Picture</label>
      <input type="file" onChange={(e) => setProfilePicture(e.target.files[0])} />
      <br />
      <input type="text" placeholder="Country of Residence" value={country} onChange={(e) => setCountry(e.target.value)} />
      <br />
     
      <input type="text" placeholder="Preferred Language" value={preferredLanguage} onChange={(e) => setPreferredLanguage(e.target.value)} />
      <br />
      <button type="submit">Register</button>
      <a href="https://wa.me/12133775942" class="whatsapp-button">Send WhatsApp Message</a>

    </form>
    </section>
  );
}

export default RegistrationForm;
